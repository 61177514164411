<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    color="primary"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item three-line>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img :src="profile.imgAvatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-h6"
            v-text="profile.title"
          ></v-list-item-title>
          <v-list-item-subtitle
            class="text-h8"
            v-text="profile.role"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-h7"
            v-text="profile.subtitle"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <v-list expand nav>
        <template v-for="(item, i) in items">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapActions } from "vuex";
//import { mapActions } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/",
      },
    ],

    routeBussinesPartnersClient: "bussinessPartnerClient",
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {},

    profile() {
      return {
        avatar: true,
        imgAvatar: "/img/acara_logo_2.png",
        title: this.$store.state.user.username,
        //title: this.$keycloak.tokenParsed.preferred_username,
        //subtitle: "MATRICE LOGIN",
        subtitle: this.$appName,
        //role: "Perfil:" + this.$store.state.user.roles,
        role: this.$store.state.user.name,
      };
    },
  },
  created() {
    //this.signIn();
    console.log("drawer creado");
    if (this.$store.state.user.sapProperties.length > 0) {
      if (this.$store.state.user.sapProperties.U_BloqueoWeb == "tNO") {
        this.setNavDrawer();
      }
    }
    //cuando todos los usuarios esten en sap, hay que comentar esta opcion
    else {
      this.setNavDrawer();
    }
  },
  methods: {
    setNavDrawer() {
      /*if (this.$can(["solicitud_usuarios_list"]) === true) {*/
      if (this.$can(["gestionar_solicitud_concesionario"]) === true) {
        let childrens = Array();
        childrens.push({
          title: "Solicitud Concesionario",
          to: "admin_solicitud_usuario",
          icon: "mdi mdi-database",
        });
        this.items.push({
          title: "Administracion",
          icon: "",
          group: "/admin",
          children: childrens,
        });
        this.items.map(this.mapItem);
      }

      //if (this.$can(["estampillas_informe_stock"]) === true) {
      if (
        this.$can(["informe_stock_estampillas"]) === true ||
        this.$can(["ingreso_stock_estampillas"]) === true
      ) {
        let childrens = Array();
        childrens.push({
          title: "Gestión de Stock",
          to: "estampillas",
          icon: "mdi mdi-database",
        });
        this.items.push({
          title: "Stock Estampillas",
          icon: "",
          group: "/stock",
          children: childrens,
        });
        this.items.map(this.mapItem);
      }

      this.items.map(this.mapItem);
      //acceso a privs de elementos_registrales_list
      /*if (this.$can(["comprar_elementos_registrales"]) === true) {*/
      if (this.$isEnabledProperty(this.$COMPRAR_ELEMENTOS_REGISTRALES)) {
        this.items.push({
          icon: "mdi-cart",
          title: "Comprar Elementos Registrales",
          to: "/elementos_registrales",
        });

        this.items.map(this.mapItem);
      }

      //acceso a privs de vender_estampillas_anmac
      if (this.$can(["vender_estampillas_anmac"]) === true) {
        this.items.push({
          icon: "mdi-cart",
          title: "Venta Estampillas Anmac",
          to: "/estampillas_ANMAC_venta",
        });

        this.items.map(this.mapItem);
      }
      //acceso a privs de elementos_aduana
      if (this.$can(["elementos_aduana_list"]) === true) {
        this.items.push({
          icon: "mdi-cart",
          title: "Elementos Aduana",
          to: "/elementos_aduana",
        });

        this.items.map(this.mapItem);
      }
      //acceso a privs de elementos_aduana
      //if (this.$can(["comprar_elementos_aduana"]) === true) {
      if (this.$isEnabledProperty(this.$COMPRAR_ELEMENTOS_ADUANA)) {
        this.items.push({
          icon: "mdi-cart",
          title: "Comprar Elementos Aduana",
          to: "/elementos_aduana_compra",
        });

        this.items.map(this.mapItem);
      }
      /*if(this.$can(["elementos_aduana_sell"]) === true) {*/
      if (this.$can(["vender_elementos_aduana"]) === true) {
        this.items.push({
          icon: "mdi-cart",
          title: "Vender Elementos Aduana",
          to: "elementos_aduana_venta",
        });

        this.items.map(this.mapItem);
      }

      //if (this.$can(["listado_transacciones"]) === true) {
      //a este priv acceden todos los perfiles
      this.items.push({
        icon: "mdi-format-list-bulleted-square",
        title: "Listado de Transacciones",
        to: "/transacciones",
      });

      this.items.map(this.mapItem);
      //}

      /*if (this.$can(["informe_stock_estampillas"]) === true) {
        this.items.push({
          icon: "mdi-postage-stamp",
          title: "Gestión de Stock de Estampillas",
          to: "/stock-estampillas/gestion_stock_estampillas",
        });

        this.items.map(this.mapItem);
      }*/

      /*if (
        this.$can(["gestionar_zona_descargas"]) === true ||
        this.$can(["listado_zona_descargas"]) === true
      ) {*/
      //a este priv acceden todos los perfiles
      this.items.push({
        icon: "mdi mdi-download",
        title: "Zona Descargas",
        to: "/zona_archivos",
      });

      this.items.map(this.mapItem);
      //}

      /*if (this.$can(["adquirir_saldo_cta_cte"]) === true) {*/
      if (this.$isEnabledProperty(this.$ADQUIRIR_SALDO_CTA_CTE)) {
        this.items.push({
          icon: "mdi-bank",
          title: "Adquirir Saldo en Cta Cte",
          to: "/adquirir_saldo_cta_cte",
        });

        this.items.map(this.mapItem);
      }

      //if (this.$can(["listado_cuenta_corriente"]) === true) {
      if (this.$isEnabledProperty(this.$LISTADO_CTA_CTE)) {
        this.items.push({
          icon: "mdi-scale-balance",
          title: "Consultar Cuenta Corriente",
          to: "/cuenta_corriente_consulta",
        });

        this.items.map(this.mapItem);
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    modificarTitleMenu(to, cant) {
      this.items[4].children.forEach((item) => {
        if (item.to === to) {
          //item.title = newTitle;
          item.cant = cant;
        }
      });
    },
    mensajes() {
      let cant = "";
      this.$axiosApi
        .get("mensajes_recibidos")
        .then((r) => {
          if (r.data.data.length > 0) {
            cant = r.data.data.length;
            this.modificarTitleMenu("recibidos", cant);
          }
        })
        .catch(function (error) {});
      let cantN = "";
      this.$axiosApi
        .get("noticias")
        .then((r) => {
          if (r.data.data.length > 0) {
            cantN = r.data.data.length;
            this.modificarTitleMenu("noticias", cantN);
          }
        })
        .catch(function (error) {});

      let cantE = "";
      let cont = 0;
      this.$axiosApi
        .get("mensajes_enviados")
        .then((r) => {
          if (r.data.data.length > 0) {
            r.data.data.forEach((item) => {
              if (item.estado === "enviado") {
                cont++;
              }
            });

            if (cont > 0) cantE = cont;
            this.modificarTitleMenu("enviados", cantE);
          }
        })
        .catch(function (error) {});
    },
    clearState() {
      console.log("upalala");
    },
  },
  watch: {
    drawer: function () {},
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
